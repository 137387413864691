/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFilePicker } from "use-file-picker";
import { createApi } from "unsplash-js";
import { Basic } from "unsplash-js/dist/methods/photos/types";
import InputLabel from "@mui/material/InputLabel";
import Modal from "../Modal";
import IconButton from "../IconButton";
import Button from "../Button";
import SearchBox from "../SearchBox";
import { MokenImage } from "../../types";
import ImageSquare from "../ImageSquare";
import axiosApi, { TOKEN_KEY } from "../../axiosApi";
import Spinner from "../Spinner";
import useAlert from "../../useAlert";
import TabController from "../Tab/TabController";
import Tab from "../Tab/Tab";
import TextInput from "../TextInput";

const PAGE_SIZE = 20;

export interface ImageSelectModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    /**
     * The available Moken image to show in the modal.
     */
    mokenImage?: MokenImage;

    /**
     * For imported days, the image that the provider set for the day.
     */
    providerImage?: MokenImage;

    /**
     * The selected image which is being used before the modal is open for a selection
     * of a new one.
     */
    selectedImage?: MokenImage | null;

    /**
     * Default location, if one is selected for the current day, to use as the
     * default filter for user images.
     */
    location?: string;

    /**
     * What happens when the user clicks on the select/accept button.
     */
    onAccept: (image: MokenImage) => void;
}

const ImageSelectModal: React.FC<ImageSelectModalProps> = ({
    visible,
    visibleHandler,
    mokenImage,
    providerImage,
    selectedImage,
    onAccept,
}) => {
    const [selected, setSelected] = useState<MokenImage | undefined>(selectedImage);
    const [errored, setErroredImageSrc] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string | undefined>(
        (selectedImage && selectedImage.image) || (selected && selected.image) || "/resources/img/img_no_encontrada.svg"
    );
    const [imageName, setImageName] = useState<string | undefined>(selected && selected.name);
    const [imageTags, setImageTags] = useState<string | undefined>(selected && selected.keyword);
    const [selectedUnsplash, setSelectedUnsplash] = useState<string | undefined>(undefined); // the string is the Unsplash image ID
    const [userImages, setUserImages] = useState<MokenImage[] | null>(null);
    const [unsplashImages, setUnsplashImages] = useState<Basic[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(""); // after submitting
    const [queryText, setQueryText] = useState<string>(""); // while typing
    const [currentUnsplashPage, setCurrentUnsplashPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [totalUnsplashPages, setTotalUnsplashPages] = useState(0);

    const { addAlert } = useAlert();
    const imagePreview = useRef<HTMLImageElement>(null);

    const token = localStorage.getItem(TOKEN_KEY);
    let access = "";
    if (token) {
        access = JSON.parse(token)?.access || "";
    }

    const unsplash = createApi({
        apiUrl: `${process.env.REACT_APP_API_URL}unsplash`,
        headers: {
            Authorization: `Bearer ${access}`,
        },
    });

    const [openFileSelector, { plainFiles, loading, errors }] = useFilePicker({
        // readAs: "DataURL",
        accept: "image/*",
        maxFileSize: 10, // in MB
        multiple: true,
    });

    const onClose = () => {
        setSearchQuery("");
        visibleHandler && visibleHandler(false);
    };

    const handleErroredImage = () => {
        if (!errored && imagePreview.current) {
            imagePreview.current.src =
                "https://images.pexels.com/photos/18623746/pexels-photo-18623746/free-photo-of-ramas-hojas-arbol-otono.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
        }
        setErroredImageSrc(true);
    };

    const onDeleteImage = (image: MokenImage) => {
        axiosApi
            .delete(`/images/${image.id}/`)
            .then(() => {
                setCurrentPage(1);
                setUserImages(null); // re-fetch images so that the deleted image does not appear anymore
            })
            .catch(() => {
                addAlert("No se ha podido eliminar la imagen.", "error");
            });
        setImageSrc("/resources/img/img_no_encontrada.svg");
    };

    /**
     * Fetches the next page of Unsplash images that satisfy the current search query.
     */
    const fetchUnsplashImages = (reset = false) => {
        unsplash.search
            .getPhotos({
                query: searchQuery,
                page: reset ? 1 : currentUnsplashPage,
                perPage: 20,
            })
            .then((r) => {
                if (r.type === "success" && r.response.results) {
                    if (reset) {
                        setUnsplashImages(r.response.results);
                        setCurrentUnsplashPage(2);
                        setTotalUnsplashPages(r.response.total_pages);
                    } else {
                        setUnsplashImages(unsplashImages.concat(r.response.results));
                        setCurrentUnsplashPage(currentUnsplashPage + 1);
                        setTotalUnsplashPages(r.response.total_pages);
                    }
                }
            });
    };

    const createUnsplashImage = async (id: string): Promise<MokenImage | undefined> => {
        // The Unsplash image has to be in the list of loaded images
        const image = unsplashImages.find((p) => p.id === id);
        if (!image) {
            return undefined;
        }

        const formData = new FormData();
        formData.append("name", `${image.user.name} (Unsplash)`);
        formData.append("unsplash_url", image.urls.regular);
        formData.append("author", image.user.name);
        formData.append("author_url", `${image.user.links.html}?utm_source=Moken&utm_medium=referral`);
        formData.append("download_location", image.links.download_location);
        return (
            axiosApi
                .post("/images/upload/", formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                })
                // eslint-disable-next-line arrow-body-style
                .then((r) => {
                    const newImage = r.data as MokenImage;
                    setSelected(newImage);
                    setCurrentPage(1);
                    setUserImages(null); // re-fetch images so that the new image appears as well
                    return newImage;
                })
                .catch(
                    () =>
                        // TODO: Treat error
                        undefined
                )
        );
    };

    let allImages: MokenImage[] = [];
    if (mokenImage && mokenImage.id > 0) {
        allImages.push(mokenImage);
    }
    // Only show provider image if it exists and if it is different than the Moken image
    if (providerImage && (!mokenImage || providerImage.id !== mokenImage.id)) {
        allImages.push(providerImage);
    }
    if (userImages) {
        allImages = allImages.concat(userImages);
    }

    const onSelectMokenImage = (image: MokenImage | undefined) => {
        setSelectedUnsplash(undefined);
        setSelected(image);
        if (image) {
            setImageSrc(image.image);
            setImageName(image.name);
            image.keyword ? setImageTags(image.keyword) : setImageTags("");
        }
    };

    const onSelectUnsplashImage = (id: string) => {
        setSelectedUnsplash(id);
        setSelected(undefined);
    };

    const fetchUserImages = (reset = false) => {
        axiosApi
            .get(
                `/images/?limit=${PAGE_SIZE}&offset=${(reset ? 0 : currentPage - 1) * PAGE_SIZE}${
                    searchQuery ? `&query=${searchQuery}` : ""
                }`
            )
            .then((r) => {
                if (reset) {
                    setTotalRecords(r.data.count);
                    setUserImages(r.data.results);
                    setCurrentPage(2);
                } else {
                    setTotalRecords(r.data.count);
                    setUserImages((currentPage === 1 || !userImages ? [] : userImages).concat(r.data.results));
                    setCurrentPage(currentPage + 1);
                }
            })
            .catch(() => {
                // TODO: Treat error
            });
    };

    const addImageName = async (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (selected) {
            setImageName(event.currentTarget.value);
            setSelected({ ...selected, name: event.currentTarget.value });
        }
    };

    const addImageKeyword = async (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (selected) {
            setImageTags(event.currentTarget.value);
            setSelected({ ...selected, keyword: event.currentTarget.value });
        }
    };

    const saveAllImages = () => {
        allImages.map((image) => {
            if (!image || image.is_moken_image) return null;
            return axiosApi
                .put(`/images/${image.id}/`, image)
                .catch(() => addAlert("No se ha podido guardar la información de la imagen.", "error"));
        });
    };

    // Update name and imgae tags
    useEffect(() => {
        allImages.forEach((image) => {
            if (selected?.id === image.id) {
                image.name = selected.name;
                image.keyword = selected.keyword;
            }
        });
    }, [selected]);

    // Initial load of images
    useEffect(() => {
        if (userImages === null) {
            fetchUserImages();
            fetchUnsplashImages(true);
        }
    }, [userImages]);

    // Uplaod images
    useEffect(() => {
        if (userImages !== null && !loading && errors.length === 0 && plainFiles.length > 0) {
            for (let i = 0; i < plainFiles.length; i += 1) {
                const formData = new FormData();
                const file = plainFiles[i];
                formData.append("name", file.name);
                formData.append("file", file, file.name);
                axiosApi
                    .post("/images/upload/", formData, {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    })
                    .then(() => {
                        setCurrentPage(1);
                        setUserImages(null); // re-fetch images so that the new image appears as well
                    })
                    .catch(() => {
                        // TODO: Treat error
                    });
            }
            // Clear files to upload
            plainFiles.length = 0;
        }
    }, [plainFiles, loading, errors]);

    useEffect(() => {
        // Query applies to both user images and unsplash
        const getImages = setTimeout(() => {
            fetchUserImages(true);
        }, 1000);

        fetchUnsplashImages(true);

        return () => clearTimeout(getImages);
    }, [searchQuery]);

    const imagesTab = (
        <Tab name="Imágenes propias">
            <div className="flex flex-col space-y-5 pt-2 h-[calc(100%-100px)]">
                <div className="max-w-[360px]">
                    <SearchBox
                        placeholder="Busca por nombre o palabra clave"
                        onChange={(s) => setSearchQuery(s)}
                        hiddenIcon
                    />
                </div>

                <div className="grid grid-cols-12">
                    <div
                        className="overflow-y-scroll col-span-8 h-[calc(100vh-670px)] sm:h-[calc(100vh-400px)] mr-8"
                        id="mokenImageContainer"
                    >
                        <InfiniteScroll
                            className="flex flex-wrap overflow-hidden"
                            dataLength={allImages.length}
                            style={{ overflow: "hidden" }}
                            next={fetchUserImages}
                            hasMore={userImages !== null && userImages.length < totalRecords}
                            loader={userImages && userImages.length > 0 && <Spinner />}
                            scrollableTarget="mokenImageContainer"
                        >
                            {allImages.map((image) => (
                                <div className="w-full md:w-1/2 xl:w-4/12 aspect-square pr-6 pb-6" key={image.id}>
                                    <ImageSquare
                                        onClick={() => onSelectMokenImage(image)}
                                        onKeyPress={() => onSelectMokenImage(image)}
                                        selected={selected && selected.id === image.id}
                                        description={image.is_unsplash ? `${image.author} (Unsplash)` : `${image.name}`}
                                        linkTo={image.is_unsplash ? image.author_url : undefined}
                                    >
                                        <img
                                            src={image.image}
                                            alt={image.name}
                                            className="w-full h-full object-cover text-center pointer-events-none object-center rounded-md"
                                            onError={() => handleErroredImage()}
                                            ref={imagePreview}
                                        />
                                        {image.is_moken_image && (
                                            <div className="absolute right-3 top-2 w-7 bg-white rounded-full p-1">
                                                <img src="../logo-icon.png" alt="Moken" />
                                            </div>
                                        )}
                                        {image.is_provider && (
                                            <div className="absolute right-3 top-2 w-7 bg-white rounded-full p-1">
                                                <img src={providerImage?.author_image?.image} alt="Proveedor" />
                                            </div>
                                        )}
                                        {!image.is_moken_image && !image.is_provider && (
                                            <div className="absolute right-[1%] top-[1%]  p-1 z-10 hidden group-hover:block">
                                                <IconButton
                                                    icon="delete"
                                                    iconSize="27px"
                                                    color="red"
                                                    onClick={() => onDeleteImage(image)}
                                                    animated
                                                    extraClass="rounded-md py-0 px-1 bg-[rgba(255,255,255,0.7)] w-8"
                                                />
                                            </div>
                                        )}
                                    </ImageSquare>
                                </div>
                            ))}
                        </InfiniteScroll>
                    </div>
                    <div className="preview col-span-12 sm:col-span-4">
                        <div className="hidden sm:block w-full aspect-square max-h-[calc(100vh-700px)]">
                            <img
                                src={imageSrc}
                                alt={selected && selected.name}
                                className={`w-full h-full object-cover text-center pointer-events-none rounded-md ${
                                    !selected && "object-none"
                                }`}
                            />
                        </div>
                        <div className="absolute sm:static bottom-[152px] left-0  flex flex-col w-full justify-evenly p-0 sm:p-4">
                            <div className="pt-2 sm:pt-6">
                                <InputLabel id="name-label">Nombre</InputLabel>
                                <TextInput
                                    id="name-label"
                                    placeholder="Escribe un nombre para tu imagen."
                                    value={imageName}
                                    onChange={addImageName}
                                />
                            </div>
                            <div className="pt-2 sm:pt-6">
                                <InputLabel id="name-label">Palabras clave</InputLabel>
                                <TextInput
                                    id="keyword"
                                    placeholder="Escribe palabras clave para encontrar más fácilmente tu imagen."
                                    value={imageTags}
                                    onChange={addImageKeyword}
                                />
                            </div>
                            <div className="mt-8 text-center sm:text-left text-2xl sm:text-base">
                                <button
                                    type="button"
                                    className="underline text-red"
                                    onClick={() => {
                                        selected && onDeleteImage(selected);
                                    }}
                                >
                                    eliminar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Tab>
    );

    const unsplashTab = (
        <Tab name="Unsplash">
            <div className="flex flex-col space-y-5 pt-2 h-[calc(100%-92px)]">
                <SearchBox
                    placeholder="Busca imágenes en Unsplash"
                    onChange={(s) => setQueryText(s)}
                    onSubmit={() => setSearchQuery(queryText)}
                />
                <div className="overflow-y-auto h-[calc(100vh-400px)]" id="unsplashImageContainer">
                    <InfiniteScroll
                        className="flex flex-wrap overflow-y-hidden"
                        style={{ overflow: "hidden" }}
                        dataLength={unsplashImages.length}
                        next={fetchUnsplashImages}
                        hasMore={currentUnsplashPage < totalUnsplashPages}
                        loader={unsplashImages.length > 0 && <Spinner />}
                        scrollableTarget="unsplashImageContainer"
                    >
                        {unsplashImages.map((p) => (
                            <div key={p.id} className="w-full sm:w-1/2 md:w-4/12 xl:w-1/4 aspect-square pr-6 pb-6">
                                <ImageSquare
                                    onClick={() => onSelectUnsplashImage(p.id)}
                                    onKeyPress={() => onSelectUnsplashImage(p.id)}
                                    selected={selectedUnsplash !== undefined && selectedUnsplash === p.id}
                                    description={`${p.user.name} (Unsplash)`}
                                    linkTo={`${p.user.links.html}?utm_source=Moken&utm_medium=referral`}
                                >
                                    <img
                                        src={p.urls.regular}
                                        alt={p.description || p.id}
                                        className="w-full h-full rounded-md object-cover text-center pointer-events-none"
                                    />
                                </ImageSquare>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
            </div>
        </Tab>
    );

    return (
        <Modal visible={visible} visibleHandler={visibleHandler}>
            <div className="sm:static flex flex-col h-[100vh] sm:h-[calc(100vh-80px)] px-4 py-8 sm:p-8">
                <div className="relative flex flex-col">
                    <div className="place-self-end -mb-5">
                        <IconButton icon="close" onClick={() => onClose()} />
                    </div>
                    <h2 className="text-2xl font-bold font-sans">Selección de imagen</h2>
                    <div className="h-[calc(100vh-240px)]">
                        <TabController>
                            {imagesTab}
                            {unsplashTab}
                        </TabController>
                    </div>
                    <div className="flex gap-4 self-end flex-wrap w-full justify-end">
                        <div className="w-full sm:w-auto h-[56px]">
                            <Button
                                label="Subir imagen"
                                type="btn_transparent"
                                icon="upload"
                                onClick={() => openFileSelector()}
                                extraClass="w-full h-full sm:h-auto"
                            />
                        </div>
                        <div className="w-full sm:w-auto h-[56px]">
                            <Button
                                label="Guardar"
                                type="btn_dark"
                                onClick={async () => {
                                    if (selected) {
                                        onAccept(selected);
                                    } else if (selectedUnsplash) {
                                        // Create a record that references this Unsplash image
                                        const image = await createUnsplashImage(selectedUnsplash);
                                        if (image) {
                                            onAccept(image);
                                            setImageSrc(image.image);
                                        }
                                    }
                                    saveAllImages();
                                    onClose();
                                }}
                                extraClass="w-full h-full sm:h-auto"
                            />
                        </div>
                    </div>
                    {loading && (
                        <p className="text-grey-2 text-xl absolute right-0 bottom-0 left-0 top-0 bg-[rgba(255,255,255,0.8)] flex justify-center items-center">
                            cargando imagen...
                        </p>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ImageSelectModal;
